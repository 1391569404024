<template>
    <div>
        <div class="headerbut box">
            <el-button type="warning">保存</el-button>
            <el-button>取消</el-button>
        </div>
        <div class="frombox">
           <el-form ref="form" :model="form" label-position="left" label-width="110px">
            <el-form-item label="活动名称">
                <el-input v-model="form.name" class="inpbox"></el-input>
            </el-form-item>
            <el-form-item label="活动名称">
                <el-input v-model="form.name" class="inpbox"></el-input>
            </el-form-item>
            <el-form-item label="当前选中 : 1区" >
                <el-input v-model="form.name" class="inpbox"></el-input>
                <el-button type="warning" size="small" style="margin-left: 20px;">清空</el-button>
            </el-form-item>
            <el-form-item label="查询国家">
                <el-input v-model="form.name" class="inpbox"></el-input>
                <el-button type="warning" size="small" style="margin-left: 20px;">查询</el-button>
            </el-form-item>
           </el-form>
        </div>
         <div class="ModifyLog ">
                <el-table
                border
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                label="序号"
                width="240">
                <template slot-scope="scope">{{ scope.row.number }}</template>
                </el-table-column>
                <el-table-column
                label="修改类容"
                width="240">
                <template slot-scope="scope">{{ scope.row.date }}</template>
                </el-table-column>
                <el-table-column
                prop="name"
                label="操作时间"
                width="240">
                </el-table-column>
                <el-table-column
                prop="address"
                label="操作人"
                show-overflow-tooltip>
                </el-table-column>
            </el-table>
            </div>
       
    </div>
</template>
<script>

export default{
    data(){
        return{
            form: {
                name: '',
            },
             tableData: [{              //表单
            number: 'xxxxxxxxxxx',
            date: 'xxxxxxxxxxx',
            name: 'xxxxxxxxxxx',
            address: 'xxxxxxxxxxxx'
            }],
        }
    }
}
</script>
<style scoped lang="scss">
.box{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}
.frombox{
    width: 80%;
    padding: 50px 15%;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #f2f2f2;
}
.inpbox{
    width: 400px;

}
.ModifyLog{
    width: 80%; 
    box-sizing: border-box;
    margin: 30px auto;
}

</style>
